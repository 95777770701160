import Aldi from "../assets/images/People/HaoSong.jpg"
import Dadang from "../assets/images/People/JingBi.jpg"
import Gilbert from "../assets/images/People/XiaoyongLi.jpg"

const TimPeople = [
    {
        id: 1,
        title: "Dr. Hao Song",
        icon: <img src={Aldi} alt="Aldi Ramdani MSN PRODUCTION" />,
        description:
            "Tianjin University",
    },
    {
        id: 2,
        title: "Dr. Jing Bi",
        icon: <img src={Dadang} alt="Dadang Darsono MSN PRODUCTION" />,
        description:
            "SIMULIA Technology",
    },
    {
        id: 3,
        title: "Xiaoyong Li",
        icon: <img src={Gilbert} alt="Gilbert Hutapea MSN PRODUCTION" />,
        description:
            "Software Engineer & Chemist",
    },
];

export default TimPeople;
