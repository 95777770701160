import MisiMSN from "../assets/images/VisiMisiImg/misi.svg"
import LayananMSN from "../assets/images/VisiMisiImg/layanan.svg"
import VisiMSN from "../assets/images/VisiMisiImg/visi.svg"

const visiMisi = [
    {
        id: 1,
        title: "Tianjin University",
        icon: <img src={MisiMSN} alt="misi MSN PRODUCTION" />,
        description:
            "School of Chemical Engineering and Technology, Tianjin, China",
    },
    {
        id: 2,
        title: "Dassault Systèmes",
        icon: <img src={LayananMSN} alt="layanan MSN PRODUCTION" />,
        description:
            "BIOVIA Materials Studio, Modeling & Simulation for Next-Generation Materials",
    },
    {
        id: 3,
        title: "More",
        icon: <img src={VisiMSN} alt="visi MSN PRODUCTION" />,
        description:
            "We are excited to invite you to collaborate on our molecular dynamics project for enzyme design and optimization. ",
    },
];

export default visiMisi;
