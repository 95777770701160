import { FaBullhorn, FaLaptop, FaUsers } from "react-icons/fa";

const Services = [
    {
      id: 1,
      title: "Join Our Community",
      link: "https://www.youtube.com/@alfax_ai",
      ltext: "Youtube Channel",
      icon: <FaUsers />,
      description:
        "Join a dynamic community of AI researchers, software engineers, and math enthusiasts. Engage in hackathons, coding challenges, research discussions, and open-source collaborations while staying ahead with the latest advancements in AI, algorithms, and applied mathematics. \n ✅ Live Webinars & Workshops \n ✅ Collaborative Open-Source Projects \n ✅ Mathematical & Algorithmic Challenges \n ✅ Career & Mentorship Support ",
    },
    {
      id: 2,
      title: "Expert Consulting Services",
      link: "/consul",
      ltext: "Consultancy",
      icon: <FaBullhorn />,
      description:
        "Partner with us to unlock innovative solutions in eCommerce optimization, causal AI research, simulation technologies, and medical AI applications. Leverage cutting-edge expertise to drive actionable insights, improve efficiency, and achieve measurable results. \n ✅ eCommerce Optimization \n ✅ Causal AI Research \n ✅ Simulation Modeling \n ✅ Custom AI Consulting ",
    },
    {
      id: 3,
      title: "Bridging Research & Industry",
      link: "/collab",
      ltext: "Collaboration",
      icon: <FaLaptop />,
      description:
        "We specialize in facilitating collaboration between research institutions and R&D companies, bridging the gap between academic innovation and real-world applications. Our platform offers strategic coordination, partnership facilitation, operational support and etc. \n ✅ Strategic Partnership Facilitation \n ✅ Project Coordination & Management \n ✅ Platform & Operational Support \n ✅ Funding & Grant Assistance",
    },
  ];

  export default Services;