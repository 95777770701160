// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".font-lobster {\n    font-family: 'Lobster', cursive;\n    letter-spacing: 2px;\n}\n\n.parent {\n    padding-right: 8%;\n    padding-left: 8%;\n}\n\n@media only screen and (max-width: 600px) {\n    .parent {\n        padding-right: 5%;\n        padding-left: 5%;\n    }\n    \n    .text-button-mobile {\n        font-size: 10px;\n    }\n}\n\n\n.visible {\n    width: 100%;\n    position: fixed;\n    top: 0px;\n    z-index: 40;\n    height: 4rem;\n    transition: 0.3s linear;\n    display: flex;\n    justify-content: stretch;\n    align-items: center;\n}\n\n.nav-hidden {\n    top: -80px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/shared/Shared.css"],"names":[],"mappings":"AAEA;IACI,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ;;;AAGA;IACI,WAAW;IACX,eAAe;IACf,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');\n\n.font-lobster {\n    font-family: 'Lobster', cursive;\n    letter-spacing: 2px;\n}\n\n.parent {\n    padding-right: 8%;\n    padding-left: 8%;\n}\n\n@media only screen and (max-width: 600px) {\n    .parent {\n        padding-right: 5%;\n        padding-left: 5%;\n    }\n    \n    .text-button-mobile {\n        font-size: 10px;\n    }\n}\n\n\n.visible {\n    width: 100%;\n    position: fixed;\n    top: 0px;\n    z-index: 40;\n    height: 4rem;\n    transition: 0.3s linear;\n    display: flex;\n    justify-content: stretch;\n    align-items: center;\n}\n\n.nav-hidden {\n    top: -80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
