const Reviews = [
  {
    id: 1,
    name: "Pratama Budiaji",
    bio: "CEO PT. Mala Sejati",
    img: "https://i.ibb.co/r53shNs/pratama-budiaji.jpg",
    description:
      "Pengerjaan website luar biasa cepat, resolusi bagus, kecepatan bagus. Terima kasih MSN PRODUCTION Recommended banget😊",
  },
  {
    id: 2,
    name: "Bapak Kurniawan",
    bio: "Pengusaha Es Buah",
    img: "https://i.ibb.co/mJJ8sm1/bapak-kurniawan.jpg",
    description:
      "Website sudah saya cek bangus bangget, pelayanan terbaik rekomandasi teman memang gak salah. Iklannya juga bagus, sudah ada prospek pelanggan 7 orang👍👍",
  },
  {
    id: 3,
    name: "Wahyu & Meta",
    bio: "Mempelai Pasangan",
    img: "https://i.ibb.co/dgLRQZw/mempelai-1.jpg",
    description:
      "Kami sangat senang atas pegerjaan website undangan sangat cepat dan efisien dan website bangus bangeet pokoknya rekomended bangett 🥰😍😍",
  },
  {
    id: 4,
    name: "Ibu Silvana",
    bio: "Founder Restoran Cilengsi",
    img: "https://i.ibb.co/FVnbpjM/ibu-silvana.jpg",
    description:
      "Luar biasa bangus dan keren pokoknya, seja pakai Goggle Ads penjualan dan profit usaha saya memenuhi target yang saya inginkan, the bestt lahh pokoknya 👌😁",
  },
];

export default Reviews;
